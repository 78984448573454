import { DynamicRouteData } from '@modules/navigation/models';

export const partnerApply: DynamicRouteData = {
    isPublic: true,
    title: 'Partner',
};
export const partnerSubmitted: DynamicRouteData = {
    isPublic: true,
    title: 'Partner',
};

export const partnerDashboard: DynamicRouteData = {
    isPublic: false,
    title: 'Partner Dashboard',
};

export const partnerConfigs = {
    partnerApply,
    partnerSubmitted,
    partnerDashboard,
};
