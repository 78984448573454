import { DynamicRouteDataMap } from '@modules/navigation/models';

import { accountConfigs } from './account.data';
import { adminConfigs } from './admin.data';
import { authConfigs } from './auth.data';
import { errorConfigs } from './error.data';
import { landingPageConfigs } from './landing-page.data';
import { allLegal } from './legal.data';
import { partnerConfigs } from './partner.data';
import { quoteConfigs } from './quote.data';
import { rigConfigs } from './rig.data';
import { siteConfigs } from './site.data';

export const routeData: DynamicRouteDataMap = {
    ...accountConfigs,
    ...allLegal,
    ...adminConfigs,
    ...authConfigs,
    ...errorConfigs,
    ...landingPageConfigs,
    ...partnerConfigs,
    ...quoteConfigs,
    ...rigConfigs,
    ...siteConfigs,
};
