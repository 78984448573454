import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Params, Router } from '@angular/router';
import {
    QuoteEnsFlowFormValue,
    QuoteRigSetFormValue,
    RigCoverageAdjustmentsFormValue,
} from '@backend-types/quote-flow-ens';
import {
    acpeDeductibleText,
    unitCollDeductibleText,
    unitCompDeductibleText,
} from '@backend-types/roc-lib';
import { ModelFormGroup, ModelFormGroupArray, ModelFormValue } from '@common/models';
import { AssertionService, ConfirmationService } from '@common/services';
import { modsValuesOptionsRecordsArray } from '@data/select-options-records-arrays.data';
import { valueLimits } from '@data/value-limits.data';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import { QuoteEnsFormStep, QuoteRigSetFormStep } from '@modules/quote/models';
import {
    QuoteEnsFormService,
    QuoteEnsRetrievalService,
    QuoteRigSetFormService,
    RateIssueKind,
    RateIssueService,
    RoundingService,
    UXPremiumBreakdown,
} from '@modules/quote/services';
import compare from 'just-compare';
import { debounceTime, Subscription } from 'rxjs';

interface ValuesRounded {
    modsValueRounded: boolean;
    camperValueRounded: boolean;
    trailerValueRounded: boolean;
    gearValueRounded: boolean;
}

@Component({
    selector: 'sbf-coverage-adjustments-form-rig',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './coverage-adjustments-form-rig.component.html',
    styleUrls: ['coverage-adjustments-form-rig.component.scss'],
})
export class CoverageAdjustmentsFormRigComponent implements OnInit, OnDestroy {
    // @Input() forAgency = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;
    @Input() editValues = false;
    @Input() endorsement?: UUID;

    quoteEnsFlowForm!: ModelFormGroup<QuoteEnsFlowFormValue>;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;

    premiumBreakdown!: UXPremiumBreakdown | null;

    valueLimits = valueLimits;
    eAcpeDeductibleText = acpeDeductibleText;
    eUnitCompDeductibleText = unitCompDeductibleText;
    eUnitCollDeductibleText = unitCollDeductibleText;

    modsValuesOptionsRecordsArray = modsValuesOptionsRecordsArray;

    subscription: Subscription = new Subscription();
    valueChangeSubscriptions: (Subscription | undefined)[] = [];

    collapsedRigs: boolean[] = [];
    valuesRounded: ValuesRounded[] = [];

    rigCoverageAdjustmentsForm: ModelFormGroupArray<{
        rigCoverageAdjustments: RigCoverageAdjustmentsFormValue;
    }> = this.fb.group({
        rigCoverageAdjustments: this.fb.array<RigCoverageAdjustmentsFormValue>([]),
    });

    constructor(
        private fb: FormBuilder,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private assertionService: AssertionService,
        private router: Router,
        private roundingService: RoundingService,
        private confirmationService: ConfirmationService,
        private rateIssueService: RateIssueService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowForm$.subscribe((quoteEnsFlowForm) => {
                this.quoteEnsFlowForm = quoteEnsFlowForm;
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$.subscribe(
                (quoteEnsFlowFormValuePure) => {
                    this._processQuoteEnsFlowForm(quoteEnsFlowFormValuePure);
                    this._tearDownSetupValueChangeSubscriptions();
                    this.changeDetectorRef.detectChanges();
                }
            )
        );
        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuotePremiumBreakdown$.subscribe(
                (premiumBreakdown) => {
                    this.premiumBreakdown = premiumBreakdown;
                    // this._initForm();
                    this.changeDetectorRef.detectChanges();
                }
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.valueChangeSubscriptions.forEach((sub) => (sub ? sub.unsubscribe() : null));
    }

    _tearDownSetupValueChangeSubscriptions() {
        this.valueChangeSubscriptions.forEach((sub) => (sub ? sub.unsubscribe() : null));
        this.valueChangeSubscriptions = [];

        this.rigCoverageAdjustmentsArray.controls.forEach(
            (rigCoverageAdjustmentsFormGroup, index) => {
                this.valueChangeSubscriptions.push(
                    rigCoverageAdjustmentsFormGroup
                        .get('camperCollDeductible')
                        ?.valueChanges.subscribe((camperCollDeductible) => {
                            this.quoteEnsFlowFormValue.rigSets[
                                index
                            ].camperDetails!.unitCollDeductible = camperCollDeductible;
                            if (this.rigCoverageAdjustmentsForm.valid) {
                                this.quoteEnsFormService.setQuoteEnsFlowForm(
                                    this.quoteEnsFlowFormValue
                                );
                            }
                        })
                );
                this.valueChangeSubscriptions.push(
                    rigCoverageAdjustmentsFormGroup
                        .get('camperCompDeductible')
                        ?.valueChanges.subscribe((camperCompDeductible) => {
                            this.quoteEnsFlowFormValue.rigSets[
                                index
                            ].camperDetails!.unitCompDeductible = camperCompDeductible;
                            if (this.rigCoverageAdjustmentsForm.valid) {
                                this.quoteEnsFormService.setQuoteEnsFlowForm(
                                    this.quoteEnsFlowFormValue
                                );
                            }
                        })
                );
                this.valueChangeSubscriptions.push(
                    rigCoverageAdjustmentsFormGroup
                        .get('trailerCollDeductible')
                        ?.valueChanges.subscribe((trailerCollDeductible) => {
                            this.quoteEnsFlowFormValue.rigSets[
                                index
                            ].trailerDetails!.unitCollDeductible = trailerCollDeductible;
                            if (this.rigCoverageAdjustmentsForm.valid) {
                                this.quoteEnsFormService.setQuoteEnsFlowForm(
                                    this.quoteEnsFlowFormValue
                                );
                            }
                        })
                );
                this.valueChangeSubscriptions.push(
                    rigCoverageAdjustmentsFormGroup
                        .get('trailerCompDeductible')
                        ?.valueChanges.subscribe((trailerCompDeductible) => {
                            this.quoteEnsFlowFormValue.rigSets[
                                index
                            ].trailerDetails!.unitCompDeductible = trailerCompDeductible;
                            if (this.rigCoverageAdjustmentsForm.valid) {
                                this.quoteEnsFormService.setQuoteEnsFlowForm(
                                    this.quoteEnsFlowFormValue
                                );
                            }
                        })
                );
                this.valueChangeSubscriptions.push(
                    rigCoverageAdjustmentsFormGroup
                        .get('modsDeductible')
                        ?.valueChanges.subscribe((modsDeductible) => {
                            this.quoteEnsFlowFormValue.rigSets[index].modsDetails!.modsDeductible =
                                modsDeductible;
                            if (this.rigCoverageAdjustmentsForm.valid) {
                                this.quoteEnsFormService.setQuoteEnsFlowForm(
                                    this.quoteEnsFlowFormValue
                                );
                            }
                        })
                );

                // Values. Debounce
                const camperValueControl = rigCoverageAdjustmentsFormGroup.get('camperValue');
                this.valueChangeSubscriptions.push(
                    camperValueControl?.valueChanges
                        .pipe(debounceTime(400))
                        .subscribe((camperValue) => {
                            const wasJustRounded = this.roundingService.roundInputValue({
                                inputValue: camperValue,
                                inputValueControl: rigCoverageAdjustmentsFormGroup.get(
                                    'camperValue'
                                ) as FormControl,
                                minValue: valueLimits.camper.min,
                                roudedTo: valueLimits.camper.roundedTo,
                            });

                            this.valuesRounded[index].camperValueRounded =
                                wasJustRounded || this.valuesRounded[index].camperValueRounded;

                            this.changeDetectorRef.detectChanges();

                            if (!wasJustRounded) {
                                if (camperValueControl.valid) {
                                    this.rateIssueService.unsetRateIssue(RateIssueKind.camperValue);
                                    this.quoteEnsFlowFormValue.rigSets[
                                        index
                                    ].camperDetails!.unitValue = camperValue;
                                } else {
                                    this.rateIssueService.setRateIssue(RateIssueKind.camperValue);
                                }
                                if (this.rigCoverageAdjustmentsForm.valid) {
                                    this.quoteEnsFormService.setQuoteEnsFlowForm(
                                        this.quoteEnsFlowFormValue
                                    );
                                }
                            }
                        })
                );
                const trailerValueControl = rigCoverageAdjustmentsFormGroup.get('trailerValue');
                this.valueChangeSubscriptions.push(
                    trailerValueControl?.valueChanges
                        .pipe(debounceTime(400))
                        .subscribe((trailerValue) => {
                            const wasJustRounded = this.roundingService.roundInputValue({
                                inputValue: trailerValue,
                                inputValueControl: rigCoverageAdjustmentsFormGroup.get(
                                    'trailerValue'
                                ) as FormControl,
                                minValue: valueLimits.trailer.min,
                                roudedTo: valueLimits.trailer.roundedTo,
                            });

                            this.valuesRounded[index].trailerValueRounded =
                                wasJustRounded || this.valuesRounded[index].trailerValueRounded;

                            this.changeDetectorRef.detectChanges();
                            if (!wasJustRounded) {
                                if (trailerValueControl.valid) {
                                    this.rateIssueService.unsetRateIssue(
                                        RateIssueKind.trailerValue
                                    );
                                    this.quoteEnsFlowFormValue.rigSets[
                                        index
                                    ].trailerDetails!.unitValue = trailerValue;
                                } else {
                                    this.rateIssueService.setRateIssue(RateIssueKind.trailerValue);
                                }
                                if (this.rigCoverageAdjustmentsForm.valid) {
                                    this.quoteEnsFormService.setQuoteEnsFlowForm(
                                        this.quoteEnsFlowFormValue
                                    );
                                }
                            }
                        })
                );
                const modsValueControl = rigCoverageAdjustmentsFormGroup.get('modsValue');
                this.valueChangeSubscriptions.push(
                    modsValueControl?.valueChanges
                        .pipe(debounceTime(400))
                        .subscribe((modsValue) => {
                            const wasJustRounded = this.roundingService.roundInputValue({
                                inputValue: modsValue,
                                inputValueControl: rigCoverageAdjustmentsFormGroup.get(
                                    'modsValue'
                                ) as FormControl,
                                minValue: valueLimits.mods.min,
                                roudedTo: valueLimits.mods.roundedTo,
                            });

                            this.valuesRounded[index].modsValueRounded =
                                wasJustRounded || this.valuesRounded[index].modsValueRounded;

                            this.changeDetectorRef.detectChanges();
                            if (!wasJustRounded) {
                                if (modsValueControl.valid) {
                                    this.rateIssueService.unsetRateIssue(RateIssueKind.modsValue);
                                    this.quoteEnsFlowFormValue.rigSets[
                                        index
                                    ].modsDetails!.modsValue = modsValue;
                                } else {
                                    this.rateIssueService.setRateIssue(RateIssueKind.modsValue);
                                }
                                if (this.rigCoverageAdjustmentsForm.valid) {
                                    this.quoteEnsFormService.setQuoteEnsFlowForm(
                                        this.quoteEnsFlowFormValue
                                    );
                                }
                            }
                        })
                );
            }
        );
    }

    _queryParams(mergeParams: Params, jumpBack: boolean): Params {
        const toReturn: Params = {
            'quote-ens-form-step': QuoteEnsFormStep.rigSet,
            ...mergeParams,
        };

        if (jumpBack) {
            if (this.endorsement) {
                toReturn['jump-back-endorsement'] = this.endorsement;
            } else {
                toReturn['jump-back-ens'] = QuoteEnsFormStep.rates;
            }
        }

        return toReturn;
    }

    addAnotherRig() {
        const currentNumberOfRigs = this.quoteEnsFormService.currentNumberOfRigs;

        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                this.quoteRigSetFormService.setActiveQuoteRigSetFormIndex(currentNumberOfRigs);
                if (this.endorsement) {
                    this.router.navigate([
                        `/agency/policies/endorsement/quote/${this.endorsement}`,
                    ]);
                } else {
                    this.router.navigate(['/agency/quotes/create']);
                }
                break;
            case AgencyAdminAccount.admin:
                this.quoteRigSetFormService.setActiveQuoteRigSetFormIndex(currentNumberOfRigs);
                this.router.navigate([`/admin/policies-ens/endorsement/quote/${this.endorsement}`]);
                break;
            case AgencyAdminAccount.account:
                this.router.navigate(['/quote/ens'], {
                    queryParams: this._queryParams(
                        {
                            'quote-rig-set-step': QuoteRigSetFormStep.rigName,
                            'rig-index': currentNumberOfRigs,
                            'endorsement-add-rig': this.endorsement,
                        },
                        false
                    ),
                });
                break;

            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }

        // https://localhost:4567/quote/ens?quote-ens-form-step=rig-set&quote-rig-set-step=rig-name
    }

    editMods(rigSetIndex: number) {
        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                if (this.endorsement) {
                    this.router.navigate([
                        `/agency/policies/endorsement/quote/${this.endorsement}`,
                    ]);
                } else {
                    this.router.navigate(['/agency/quotes/create']);
                }
                break;
            case AgencyAdminAccount.admin:
                this.router.navigate([`/admin/policies-ens/endorsement/quote/${this.endorsement}`]);
                break;
            case AgencyAdminAccount.account:
                this.router.navigate(['/quote/ens'], {
                    queryParams: this._queryParams(
                        {
                            'quote-rig-set-step': QuoteRigSetFormStep.modsDetails,
                            'rig-index': rigSetIndex,
                        },
                        true
                    ),
                });
                break;

            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }
    }

    editTrailer(rigSetIndex: number) {
        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                if (this.endorsement) {
                    this.router.navigate([
                        `/agency/policies/endorsement/quote/${this.endorsement}`,
                    ]);
                } else {
                    this.router.navigate(['/agency/quotes/create']);
                }
                break;
            case AgencyAdminAccount.admin:
                this.router.navigate([`/admin/policies-ens/endorsement/quote/${this.endorsement}`]);
                break;
            case AgencyAdminAccount.account:
                this.router.navigate(['/quote/ens'], {
                    queryParams: this._queryParams(
                        {
                            'quote-rig-set-step': QuoteRigSetFormStep.trailerDetails,
                            'rig-index': rigSetIndex,
                        },
                        true
                    ),
                });
                break;

            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }
    }

    editCamper(rigSetIndex: number) {
        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                if (this.endorsement) {
                    this.router.navigate([
                        `/agency/policies/endorsement/quote/${this.endorsement}`,
                    ]);
                } else {
                    this.router.navigate(['/agency/quotes/create']);
                }
                break;
            case AgencyAdminAccount.admin:
                this.router.navigate([`/admin/policies-ens/endorsement/quote/${this.endorsement}`]);
                break;
            case AgencyAdminAccount.account:
                this.router.navigate(['/quote/ens'], {
                    queryParams: this._queryParams(
                        {
                            'quote-rig-set-step': QuoteRigSetFormStep.camperDetails,
                            'rig-index': rigSetIndex,
                        },
                        true
                    ),
                });
                break;

            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }
    }

    addMods(rigSetIndex: number) {
        this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.modsCoverage = true;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
        if (!this.quoteEnsFlowFormValue.rigSets[rigSetIndex].modsDetails?.modsValue) {
            this.editMods(rigSetIndex);
        }
    }

    addTrailer(rigSetIndex: number) {
        this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.trailerCoverage = true;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
        if (!this.quoteEnsFlowFormValue.rigSets[rigSetIndex].trailerDetails?.unitAge) {
            this.editTrailer(rigSetIndex);
        }
    }

    addCamper(rigSetIndex: number) {
        this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.camperCoverage = true;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
        if (!this.quoteEnsFlowFormValue.rigSets[rigSetIndex].camperDetails?.unitAge) {
            this.editCamper(rigSetIndex);
        }
    }

    removeMods(rigSetIndex: number) {
        if (!this.quoteEnsFormService.canRemoveCoverage(rigSetIndex)) {
            return;
        }
        this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.modsCoverage = false;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
    }

    removeTrailer(rigSetIndex: number) {
        if (!this.quoteEnsFormService.canRemoveCoverage(rigSetIndex)) {
            return;
        }
        this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.trailerCoverage = false;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
    }

    removeCamper(rigSetIndex: number) {
        if (!this.quoteEnsFormService.canRemoveCoverage(rigSetIndex)) {
            return;
        }
        this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.camperCoverage = false;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
    }

    // private _canRemoveCoverage(rigSetIndex: number): boolean {
    //     let coverages = 0;
    //     if (this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.modsCoverage) {
    //         coverages++;
    //     }
    //     if (this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.camperCoverage) {
    //         coverages++;
    //     }
    //     if (this.quoteEnsFlowFormValue.rigSets[rigSetIndex].coverageSelect.trailerCoverage) {
    //         coverages++;
    //     }
    //     if (coverages === 1) {
    //         this.confirmationService.confirm$({
    //             title: 'UNABLE TO REMOVE LAST COVERAGE',
    //             text: `You must have at least one covereage on your Rig`,
    //             confirmationLabel: 'OKAY',
    //             noCancel: true,
    //         });

    //         return false;
    //     }
    //     return true;
    // }

    toggleCollapse(index: number) {
        this.collapsedRigs[index] = !this.collapsedRigs[index];
    }

    removeRig(rigSetIndex: number) {
        const rigName = this.quoteEnsFlowFormValue.rigSets[rigSetIndex]?.rigName;

        this.confirmationService
            .confirm$({
                title: `Are you sure you want to remove ${rigName}?`,
                text: `Warning: This will permanently remove ${rigName} from your quote`,
                confirmationLabel: 'CONFIRM',
            })
            .subscribe((confirm) => {
                if (confirm) {
                    this.quoteRigSetFormService.deleteRigAtIndex(rigSetIndex);
                    this.rigCoverageAdjustmentsArray.controls.splice(rigSetIndex, 1);
                    this.quoteEnsFlowFormValue.rigSets.splice(rigSetIndex, 1);
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                    return;
                }
            });
    }

    // eslint-disable-next-line complexity
    private _processQuoteEnsFlowForm(quoteEnsFlowFormValuePure: QuoteEnsFlowFormValue | null) {
        if (quoteEnsFlowFormValuePure === null) {
            return;
        }
        if (compare(this.quoteEnsFlowFormValue, quoteEnsFlowFormValuePure)) {
            return;
        }
        this.quoteEnsFlowFormValue = quoteEnsFlowFormValuePure;

        // INFO: Clean up first
        // https://stackoverflow.com/questions/41852183/remove-all-items-from-a-formarray-in-angular
        this.rigCoverageAdjustmentsArray.clear({ emitEvent: false });
        while (this.rigCoverageAdjustmentsArray.length !== 0) {
            this.rigCoverageAdjustmentsArray.removeAt(0, { emitEvent: false });
        }

        this.quoteEnsFlowFormValue.rigSets.forEach((rigSet, index) => {
            this.collapsedRigs[index] = false;
            this.valuesRounded[index] = {
                modsValueRounded: false,
                camperValueRounded: false,
                trailerValueRounded: false,
                gearValueRounded: false,
            };
            this.rigCoverageAdjustmentsArray.push(
                this._newRigCoverageAdjustmentFormGroup(
                    {
                        modsValue: this.assertionService.orNULL(rigSet.modsDetails?.modsValue),
                        modsDeductible: this.assertionService.orNULL(
                            rigSet.modsDetails?.modsDeductible
                        ),
                        camperValue: this.assertionService.orNULL(rigSet.camperDetails?.unitValue),
                        camperCompDeductible: this.assertionService.orNULL(
                            rigSet.camperDetails?.unitCompDeductible
                        ),
                        camperCollDeductible: this.assertionService.orNULL(
                            rigSet.camperDetails?.unitCollDeductible
                        ),
                        trailerValue: this.assertionService.orNULL(
                            rigSet.trailerDetails?.unitValue
                        ),
                        trailerCompDeductible: this.assertionService.orNULL(
                            rigSet.trailerDetails?.unitCompDeductible
                        ),
                        trailerCollDeductible: this.assertionService.orNULL(
                            rigSet.trailerDetails?.unitCollDeductible
                        ),
                    },
                    rigSet
                ),
                { emitEvent: false }
            );
        });
    }

    private _newRigCoverageAdjustmentFormGroup(
        coverageAdjustmentsFormValue: ModelFormValue<RigCoverageAdjustmentsFormValue>,
        rigSet: QuoteRigSetFormValue
    ) {
        const modsValueValidators: ValidatorFn[] = [
            Validators.min(valueLimits.mods.min),
            Validators.max(valueLimits.mods.max),
        ];
        const modsDeductibleValidators: ValidatorFn[] = [];
        const camperValueValidators: ValidatorFn[] = [
            Validators.min(valueLimits.camper.min),
            Validators.max(valueLimits.camper.max),
        ];
        const camperCompDeductibleValidators: ValidatorFn[] = [];
        const camperCollDeductibleValidators: ValidatorFn[] = [];
        const trailerValueValidators: ValidatorFn[] = [
            Validators.min(valueLimits.trailer.min),
            Validators.max(valueLimits.trailer.max),
        ];
        const trailerCompDeductibleValidators: ValidatorFn[] = [];
        const trailerCollDeductibleValidators: ValidatorFn[] = [];

        if (rigSet.coverageSelect.modsCoverage) {
            modsValueValidators.push(Validators.required);
            modsDeductibleValidators.push(Validators.required);
        }
        if (rigSet.coverageSelect.camperCoverage) {
            camperValueValidators.push(Validators.required);
            camperCompDeductibleValidators.push(Validators.required);
            camperCollDeductibleValidators.push(Validators.required);
        }
        if (rigSet.coverageSelect.trailerCoverage) {
            trailerValueValidators.push(Validators.required);
            trailerCompDeductibleValidators.push(Validators.required);
            trailerCollDeductibleValidators.push(Validators.required);
        }

        return this.fb.group(
            {
                modsValue: [coverageAdjustmentsFormValue.modsValue, modsValueValidators],
                modsDeductible: [
                    coverageAdjustmentsFormValue.modsDeductible,
                    modsDeductibleValidators,
                ],
                camperValue: [coverageAdjustmentsFormValue.camperValue, camperValueValidators],
                camperCompDeductible: [
                    coverageAdjustmentsFormValue.camperCompDeductible,
                    camperCompDeductibleValidators,
                ],
                camperCollDeductible: [
                    coverageAdjustmentsFormValue.camperCollDeductible,
                    camperCollDeductibleValidators,
                ],
                trailerValue: [coverageAdjustmentsFormValue.trailerValue, trailerValueValidators],
                trailerCompDeductible: [
                    coverageAdjustmentsFormValue.trailerCompDeductible,
                    trailerCompDeductibleValidators,
                ],
                trailerCollDeductible: [
                    coverageAdjustmentsFormValue.trailerCollDeductible,
                    trailerCollDeductibleValidators,
                ],
            }
            // {
            //     updateOn: 'blur',
            // }
        );
    }

    /* Accessor Methods */

    get rigCoverageAdjustmentsArray() {
        return this.rigCoverageAdjustmentsForm.get('rigCoverageAdjustments') as FormArray;
    }
}
