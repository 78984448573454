import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ExistingCoveragePullStatus } from '@backend-types/coverage-verification';
import { DriverTypedForQuote } from '@backend-types/driver';
import { PaymentPlanName } from '@backend-types/payment-plan';
import { QuotePolicyKind } from '@backend-types/quote-ens';
import {
    EnsGearDetailsFormValue,
    EnsPersonalInfoFormValue,
    EnsSpecialCoveragesFormValue,
    ESignatureConsent,
    ExistingCoverageManualFormValue,
    GearCoverageType,
    HomeBaseFormValue,
    QuoteEnsFlowFormValue,
    QuoteRigSetFormValue,
    RateCallTwoFormValue,
} from '@backend-types/quote-flow-ens';
import {
    CampsiteLiabilityLimit,
    EmergencyExpenseLimit,
    ENSTermRateKey,
    OffRoadRecoveryLimit,
    ScheduledMedicalBenefitLimit,
    TowRoadsideLimit,
} from '@backend-types/roc-lib';
import { UtmLocalStorage } from '@backend-types/utm';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import {
    AssertionService,
    ConfirmationService,
    HashService,
    LogService,
    UtilityService,
    UtmService,
} from '@common/services';
import { validateQuoteEnsFlowForm } from '@common/validators';
import clone from 'just-clone';
import db from 'just-debounce-it';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';

import { QuoteCacheService } from './quote-cache.service';

const CACHE_DEBOUNCE_TIME = 600;

const defaultEnsSpecialCoverages: EnsSpecialCoveragesFormValue = {
    emergencyExpense: {
        selected: true,
        emergencyExpenseLimit: EmergencyExpenseLimit.oneThousand,
    },
    scheduledMedicalBenefit: {
        selected: false, // INFO: FOR NOW
        scheduledMedicalBenefitLimit: ScheduledMedicalBenefitLimit.sixtyThousand,
    },
    towRoadside: {
        selected: true,
        towRoadsideLimit: TowRoadsideLimit.fiveHundred,
    },
    campsiteLiability: {
        selected: false, // INFO: FOR NOW
        campsiteLiabilityLimit: CampsiteLiabilityLimit.fiftyThousand,
    },
    offRoadRecovery: {
        selected: true,
        offRoadRecoveryLimit: OffRoadRecoveryLimit.fiveThousand,
    },
};

const defaultEnsGearDetailsFormValue: ModelFormValue<EnsGearDetailsFormValue> = {
    gearValue: null,
    gearCoverageType: GearCoverageType.actualCashValue,
};

@Injectable({
    providedIn: 'root',
})
export class QuoteEnsFormService {
    private _quoteEnsFlowForm$ = new ReplaySubject<ModelFormGroup<QuoteEnsFlowFormValue>>(1);
    private _quoteEnsFlowFormValue$ = new ReplaySubject<ModelFormValue<QuoteEnsFlowFormValue>>(1);
    private _quoteEnsFlowFormInitial$ = new BehaviorSubject<QuoteEnsFlowFormValue | null>(null);
    private _quoteEnsFlowFormValuePure$ = new BehaviorSubject<QuoteEnsFlowFormValue | null>(null);
    private _quoteEnsFlowFormInitialModified$ = new BehaviorSubject<boolean>(false);

    private _quoteEnsFlowForm: ModelFormGroup<QuoteEnsFlowFormValue> = this.fb.nonNullable.group(
        {
            rigSets: new FormControl<QuoteRigSetFormValue[] | null>(null, Validators.required),
            gearCoverage: new FormControl<boolean | null>(false, Validators.required),
            gearDetails: new FormControl<EnsGearDetailsFormValue | null>(
                defaultEnsGearDetailsFormValue as EnsGearDetailsFormValue
            ),
            homeBase: new FormControl<HomeBaseFormValue | null>(null),
            ensPersonalInfo: new FormControl<EnsPersonalInfoFormValue | null>(null),
            specialCoverages: new FormControl<EnsSpecialCoveragesFormValue | null>(
                defaultEnsSpecialCoverages
            ),
            pledge: new FormControl<boolean | null>(false),
            documentsAcknowledged: new FormControl<boolean | null>(false),
            electronicDocuments: new FormControl<boolean | null>(true),
            autoBillingOptIn: new FormControl<boolean | null>(true),
            term: new FormControl<ENSTermRateKey | null>(ENSTermRateKey.sixMonth),
            timeZone: new FormControl<string>(
                this.utilityService.intl.DateTimeFormat().resolvedOptions().timeZone
            ),
            effectiveDate: new FormControl<string | null>(null),
            fromPolicyId: new FormControl<string | null>(null),
            eSignatureConsentGiven: new FormControl<ESignatureConsent | null>(null),
            ipAddress: new FormControl<string | null>(null),
            selectedPaymentPlan: new FormControl<PaymentPlanName | null>(null),
            rc2: new FormControl<RateCallTwoFormValue | null>(null),
            organizationId: new FormControl<UUID | null>(null),
            agentId: new FormControl<UUID | null>(null),
            existingCoveragePullStatus: new FormControl<ExistingCoveragePullStatus | null>(
                ExistingCoveragePullStatus.notPulled
            ),
            existingCoverageManual: new FormControl<ExistingCoverageManualFormValue | null>(null),

            quotePolicyKind: new FormControl<QuotePolicyKind | null>(null),
            utm: new FormControl<UtmLocalStorage | null>(this.utmService.utm),
            quotePrincipalDriver: new FormControl<DriverTypedForQuote | null>(null),
            quoteAdditionalDrivers: new FormControl<DriverTypedForQuote[] | null>(null),
        },
        { validators: [validateQuoteEnsFlowForm()] }
    );

    cacheForm = db(() => {
        this.logService.debug('CACHING_QUOTE_ENS_FLOW_FORM_VALUE');
        const quoteEnsFlowFormValue = this.quoteEnsFlowFormValue;
        if (quoteEnsFlowFormValue.pledge) {
            this.quoteCacheService.cachedQuoteEnsFlowFormValue = quoteEnsFlowFormValue;
        } else {
            this.logService.debug('NOT_CACHING_QUOTE_ENS_FLOW_FORM_VALUE_SINCE_NO_PLEDGE');
        }
    }, CACHE_DEBOUNCE_TIME);

    constructor(
        private fb: FormBuilder,
        private logService: LogService,
        private quoteCacheService: QuoteCacheService,
        private assertionService: AssertionService,
        private utilityService: UtilityService,
        private hashService: HashService,
        private utmService: UtmService,
        private confirmationService: ConfirmationService
    ) {
        // eslint-disable-next-line complexity
        this._quoteEnsFlowForm.valueChanges.subscribe((quoteEnsFlowFormValue) => {
            this._quoteEnsFlowFormValue$.next(this.quoteEnsFlowFormValue);
            // if (quoteEnsFlowFormValue === null) {
            //     this._quoteEnsFlowFormValuePure$.next(null);
            //     return;
            // }

            let hasDirtyInvalid = false;
            for (const key in this._quoteEnsFlowForm.controls) {
                if (Object.prototype.hasOwnProperty.call(this._quoteEnsFlowForm.controls, key)) {
                    const control =
                        this._quoteEnsFlowForm.controls[key as keyof QuoteEnsFlowFormValue];
                    if (control?.invalid && control?.dirty) {
                        hasDirtyInvalid = true;
                    }
                }
            }
            if (!hasDirtyInvalid) {
                this.cacheForm();
            }
            try {
                const quoteEnsFlowFormValue = this._quoteEnsFlowFormValuePure();
                if (quoteEnsFlowFormValue.pledge) {
                    this._quoteEnsFlowFormValuePure$.next(quoteEnsFlowFormValue);
                } else {
                    this._quoteEnsFlowFormValuePure$.next(null);
                }
            } catch (error) {
                // INFO: Don't handle any errors here, we could still be building the form
            }
        });

        this._quoteEnsFlowForm$.next(this._quoteEnsFlowForm);
        this._quoteEnsFlowFormValue$.next(this._quoteEnsFlowFormValue());

        const cachedQuote = this.quoteCacheService.cachedQuoteEnsFlowFormValue;
        if (cachedQuote) {
            this.quoteEnsFlowFormValue = cachedQuote;
        }

        combineLatest([this._quoteEnsFlowFormValue$, this._quoteEnsFlowFormInitial$]).subscribe(
            ([quoteEnsFlowFormValue, quoteEnsFlowFormInitial]) => {
                if (!quoteEnsFlowFormInitial) {
                    return;
                }
                // this.diffService.diff(quoteEnsFlowFormValue, quoteEnsFlowFormInitial);
                let quoteEnsFlowFormValuePure: QuoteEnsFlowFormValue;
                try {
                    quoteEnsFlowFormValuePure = this._quoteEnsFlowFormValuePure();
                } catch (error) {
                    return;
                }
                try {
                    const sameHash =
                        this.hashService.hashFromQuoteEnsFlowFormValue(
                            quoteEnsFlowFormValuePure
                        ) ===
                        this.hashService.hashFromQuoteEnsFlowFormValue(quoteEnsFlowFormInitial);
                    this._quoteEnsFlowFormInitialModified$.next(!sameHash);
                } catch (error) {
                    // INFO: When adding rigin endorsement information may not be complete
                }
            }
        );
    }

    get quoteEnsFlowForm$(): Observable<ModelFormGroup<QuoteEnsFlowFormValue>> {
        return this._quoteEnsFlowForm$.asObservable();
    }

    get quoteEnsFlowFormIsValid(): boolean {
        return this._quoteEnsFlowForm.valid;
    }

    setQuoteEnsFlowFormInitial(quoteEnsFlowFormValue: QuoteEnsFlowFormValue) {
        this._quoteEnsFlowFormInitial$.next(clone(quoteEnsFlowFormValue));
    }

    get quoteEnsFlowFormInitialModified$(): Observable<boolean> {
        return this._quoteEnsFlowFormInitialModified$.asObservable();
    }

    revertToInitial(): void {
        const initialValue = this._quoteEnsFlowFormInitial$.value;
        if (!initialValue) {
            return;
        }
        this.setQuoteEnsFlowForm(initialValue);
    }

    setQuoteEnsFlowForm(quoteEnsFlowFormValue: QuoteEnsFlowFormValue, emitEvent = true) {
        this._quoteEnsFlowForm.setValue(quoteEnsFlowFormValue, { emitEvent });
        this._quoteEnsFlowForm$.next(this._quoteEnsFlowForm);
        // this._quoteEnsFlowFormValue$.next(this._quoteEnsFlowForm.value); // Why were we doing this?
    }

    get quoteEnsFlowFormValue$(): Observable<Partial<ModelFormValue<QuoteEnsFlowFormValue>>> {
        return this._quoteEnsFlowFormValue$.asObservable();
    }

    get quoteEnsFlowFormValue(): ModelFormValue<QuoteEnsFlowFormValue> {
        return this._quoteEnsFlowFormValue();
    }

    set quoteEnsFlowFormValue(quoteEnsFlowFormValue: ModelFormValue<QuoteEnsFlowFormValue>) {
        try {
            this._quoteEnsFlowForm.setValue(quoteEnsFlowFormValue);
            this._quoteEnsFlowForm.updateValueAndValidity();
        } catch (error) {
            this.quoteCacheService.cachedQuoteEnsFlowFormValue = null;
            this.logService.error(
                (error as Error).message,
                'UNABLE_TO_SET_VALUE_IN__QUOTE_ENS_FORM__SO_CLEARING_CACHE'
            );
        }
    }

    get quoteEnsFlowFormValuePure$(): Observable<QuoteEnsFlowFormValue | null> {
        return this._quoteEnsFlowFormValuePure$.asObservable();
    }

    // get quoteEnsFlowFormValuePure(): QuoteEnsFlowFormValue | null {
    //     return this._quoteEnsFlowFormValuePure$.value;
    //     // let quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    //     // try {
    //     //     quoteEnsFlowFormValue = this._quoteEnsFlowFormValuePure();
    //     // } catch (error) {
    //     //     this.router.navigate(['/quote/ens'], {
    //     //         queryParams: { 'quote-ens-form-step': 'intro' },
    //     //     });
    //     //     throw error;
    //     // }

    //     // return quoteEnsFlowFormValue;
    // }

    get hasGearCoverage(): boolean {
        return !!this._quoteEnsFlowForm.get('gearCoverage')?.value;
    }

    get rigSetZero() {
        const rigSets = this._quoteEnsFlowForm.get('rigSets')?.value;
        if (!rigSets) {
            throw new Error('NO_RIG_SETS_ZERO');
        }
        return rigSets[0];
    }

    get currentNumberOfRigs(): number {
        return this._quoteEnsFlowFormValuePure().rigSets.length;
    }

    get hasEnteredPersonalInfo(): boolean {
        return this._quoteEnsFlowForm.controls.ensPersonalInfo.value !== null;
    }

    noGear() {
        this._quoteEnsFlowForm.patchValue({
            gearCoverage: false,
        });
    }

    addGear() {
        this._quoteEnsFlowForm.patchValue({
            gearCoverage: true,
        });
    }

    setGear(gearCoverage: boolean) {
        this._quoteEnsFlowForm.patchValue({
            gearCoverage,
        });
    }

    setAutoBillOptIn(autoBillingOptIn: boolean) {
        this._quoteEnsFlowForm.patchValue({
            autoBillingOptIn,
        });
    }

    setDocumentsAcknowledged(documentsAcknowledged: boolean) {
        this._quoteEnsFlowForm.patchValue({
            documentsAcknowledged,
        });
    }

    setElectronicDocuments(electronicDocuments: boolean) {
        this._quoteEnsFlowForm.patchValue({
            electronicDocuments,
        });
    }

    setESignatureAcknowledged(eSignatureAcknowledged: boolean) {
        if (eSignatureAcknowledged) {
            this._quoteEnsFlowForm.patchValue({
                eSignatureConsentGiven: {
                    date: new Date().toISOString(),
                },
            });
        } else {
            this._quoteEnsFlowForm.patchValue({
                eSignatureConsentGiven: null,
            });
        }
    }

    setSelectedPaymentPlan(selectedPaymentPlan: PaymentPlanName) {
        this._quoteEnsFlowForm.patchValue({
            selectedPaymentPlan,
        });
    }

    setOrganizationId(organizationId: UUID) {
        this._quoteEnsFlowForm.patchValue({
            organizationId,
        });
    }

    setAgentId(agentId: UUID) {
        this._quoteEnsFlowForm.patchValue({
            agentId,
        });
    }

    setUtm(utm: UtmLocalStorage) {
        this._quoteEnsFlowForm.patchValue({
            utm,
        });
    }

    setExistingCoveragePullStatus(existingCoveragePullStatus: ExistingCoveragePullStatus) {
        this._quoteEnsFlowForm.patchValue({
            existingCoveragePullStatus,
        });
    }

    reset() {
        const quoteEnsFlowFormDefaultValues: ModelFormValue<QuoteEnsFlowFormValue> = {
            rigSets: null,
            gearCoverage: false,
            gearDetails: defaultEnsGearDetailsFormValue as EnsGearDetailsFormValue,
            homeBase: null,
            ensPersonalInfo: null,
            specialCoverages: defaultEnsSpecialCoverages,
            pledge: false,
            documentsAcknowledged: false,
            electronicDocuments: true,
            autoBillingOptIn: true,
            term: ENSTermRateKey.sixMonth,
            timeZone: this.utilityService.intl.DateTimeFormat().resolvedOptions().timeZone,
            effectiveDate: null,
            fromPolicyId: null,
            eSignatureConsentGiven: null,
            ipAddress: null,
            selectedPaymentPlan: null,
            rc2: null,
            organizationId: null,
            agentId: null,
            existingCoveragePullStatus: ExistingCoveragePullStatus.notPulled,
            existingCoverageManual: null,
            quotePolicyKind: null,
            utm: this.utmService.utm,
            quotePrincipalDriver: null,
            quoteAdditionalDrivers: null,
        };

        this._quoteEnsFlowFormInitial$.next(null);
        this.quoteCacheService.clearQuoteEnsLocalStorageCache();
        this._quoteEnsFlowForm.reset(quoteEnsFlowFormDefaultValues);
        this.quoteCacheService.clearQuoteEnsLocalStorageCache();

        this._quoteEnsFlowFormValuePure$.next(null);
        this._quoteEnsFlowFormValue$.next(this._quoteEnsFlowFormValue());
        this._quoteEnsFlowForm$.next(this._quoteEnsFlowForm);

        this.quoteCacheService.clearQuoteEnsLocalStorageCache();
        this.utilityService.localStorage.removeItem('ActiveQuoteEnsUserId');
        // INFO: Have to reset twice so that all nulls propegate...
        this._quoteEnsFlowForm.reset(quoteEnsFlowFormDefaultValues);
    }

    private _quoteEnsFlowFormValue(): ModelFormValue<QuoteEnsFlowFormValue> {
        const {
            rigSets,
            gearCoverage,
            gearDetails,
            homeBase,
            ensPersonalInfo,
            specialCoverages,
            pledge,
            autoBillingOptIn,
            documentsAcknowledged,
            electronicDocuments,
            term,
            timeZone,
            effectiveDate,
            fromPolicyId,
            eSignatureConsentGiven,
            ipAddress,
            selectedPaymentPlan,
            rc2,
            organizationId,
            agentId,
            existingCoveragePullStatus,
            existingCoverageManual,
            quotePolicyKind,
            utm,
            quotePrincipalDriver,
            quoteAdditionalDrivers,
        } = this._quoteEnsFlowForm.value;
        return {
            rigSets: this.assertionService.orNULL(rigSets),
            gearCoverage: this.assertionService.orNULLBoolean(gearCoverage),
            gearDetails: this.assertionService.orNULL(gearDetails),
            homeBase: this.assertionService.orNULL(homeBase),
            ensPersonalInfo: this.assertionService.orNULL(ensPersonalInfo),
            specialCoverages: this.assertionService.orNULL(specialCoverages),
            pledge: this.assertionService.orNULL(pledge),
            autoBillingOptIn: this.assertionService.orNULLBoolean(autoBillingOptIn),
            documentsAcknowledged: this.assertionService.orNULLBoolean(documentsAcknowledged),
            electronicDocuments: this.assertionService.orNULLBoolean(electronicDocuments),
            term: this.assertionService.orNULL(term),
            timeZone: this.assertionService.orNULL(timeZone),
            effectiveDate: this.assertionService.orNULL(effectiveDate),
            fromPolicyId: this.assertionService.orNULL(fromPolicyId),
            eSignatureConsentGiven: this.assertionService.orNULL(eSignatureConsentGiven),
            existingCoveragePullStatus: this.assertionService.orNULL(existingCoveragePullStatus),
            existingCoverageManual: this.assertionService.orNULL(existingCoverageManual),
            ipAddress: this.assertionService.orNULL(ipAddress),
            selectedPaymentPlan: this.assertionService.orNULL(selectedPaymentPlan),
            rc2: this.assertionService.orNULL(rc2),
            organizationId: this.assertionService.orNULL(organizationId),
            agentId: this.assertionService.orNULL(agentId),
            quotePolicyKind: this.assertionService.orNULL(quotePolicyKind),
            utm: this.assertionService.orNULL(utm),
            quotePrincipalDriver: this.assertionService.orNULL(quotePrincipalDriver),
            quoteAdditionalDrivers: this.assertionService.orNULL(quoteAdditionalDrivers),
        };
    }
    private _quoteEnsFlowFormValuePure(): QuoteEnsFlowFormValue {
        if (this._quoteEnsFlowForm.invalid) {
            throw new Error('INVALID_FORM');
        }
        const {
            rigSets,
            gearCoverage,
            gearDetails,
            homeBase,
            ensPersonalInfo,
            specialCoverages,
            pledge,
            autoBillingOptIn,
            documentsAcknowledged,
            electronicDocuments,
            term,
            timeZone,
            effectiveDate,
            fromPolicyId,
            eSignatureConsentGiven,
            ipAddress,
            selectedPaymentPlan,
            rc2,
            organizationId,
            agentId,
            existingCoveragePullStatus,
            existingCoverageManual,
            quotePolicyKind,
            utm,
            quotePrincipalDriver,
            quoteAdditionalDrivers,
        } = this._quoteEnsFlowForm.value;

        this.assertionService.isDefinedOrThrow(rigSets);
        this.assertionService.isDefinedOrThrow(gearCoverage);
        this.assertionService.isDefinedOrThrow(specialCoverages);
        this.assertionService.isDefinedOrThrow(homeBase);
        this.assertionService.isDefinedOrThrow(ensPersonalInfo);
        this.assertionService.isDefinedOrThrow(pledge);
        this.assertionService.isDefinedOrThrow(autoBillingOptIn);
        this.assertionService.isDefinedOrThrow(documentsAcknowledged);
        this.assertionService.isDefinedOrThrow(electronicDocuments);
        this.assertionService.isDefinedOrThrow(term);
        this.assertionService.isDefinedOrThrow(timeZone);
        this.assertionService.isDefinedOrThrow(existingCoveragePullStatus);

        this.assertionService.isNotUndefinedOrThrow(gearDetails);
        this.assertionService.isDefinedOrThrow(existingCoveragePullStatus);
        // this.assertionService.isNotUndefinedOrThrow(ensPersonalInfo);

        const quoteEnsFlowFormValue = {
            rigSets: rigSets,
            gearCoverage: gearCoverage,
            gearDetails: gearDetails,
            homeBase: homeBase,
            ensPersonalInfo: ensPersonalInfo,
            specialCoverages: specialCoverages,
            pledge: pledge,
            autoBillingOptIn: autoBillingOptIn,
            documentsAcknowledged: documentsAcknowledged,
            electronicDocuments: electronicDocuments,
            term: term,
            timeZone: timeZone,
            effectiveDate: this.assertionService.orNULL(effectiveDate),
            fromPolicyId: this.assertionService.orNULL(fromPolicyId),
            eSignatureConsentGiven: this.assertionService.orNULL(eSignatureConsentGiven),
            ipAddress: this.assertionService.orNULL(ipAddress),
            selectedPaymentPlan: this.assertionService.orNULL(selectedPaymentPlan),
            rc2: this.assertionService.orNULL(rc2),
            organizationId: this.assertionService.orNULL(organizationId),
            agentId: this.assertionService.orNULL(agentId),
            existingCoverageManual: this.assertionService.orNULL(existingCoverageManual),
            existingCoveragePullStatus: existingCoverageManual
                ? ExistingCoveragePullStatus.skippedForQuote
                : existingCoveragePullStatus,
            quotePolicyKind: this.assertionService.orNULL(quotePolicyKind),
            utm: this.assertionService.orNULL(utm),
            quotePrincipalDriver: this.assertionService.orNULL(quotePrincipalDriver),
            quoteAdditionalDrivers: this.assertionService.orNULL(quoteAdditionalDrivers),
        };

        return clone({
            ...quoteEnsFlowFormValue,
            quotePrincipalDriver: quotePrincipalDriver
                ? quotePrincipalDriver
                : this._createPrincipalDriver(quoteEnsFlowFormValue),
        });
    }

    private _createPrincipalDriver(
        quoteEnsFlowFormValue: QuoteEnsFlowFormValue
    ): DriverTypedForQuote {
        return {
            firstName: quoteEnsFlowFormValue.ensPersonalInfo.firstName,
            lastName: quoteEnsFlowFormValue.ensPersonalInfo.lastName,
            street: quoteEnsFlowFormValue.homeBase.street,
            street2: '',
            city: quoteEnsFlowFormValue.homeBase.city,
            state: quoteEnsFlowFormValue.homeBase.state,
            postalCode: quoteEnsFlowFormValue.homeBase.zip,
            country: quoteEnsFlowFormValue.homeBase.country,
            organizationId:
                quoteEnsFlowFormValue.organizationId || '00000000-0000-0000-0000-300000000003', // Defaults to Tredder Org
            email: quoteEnsFlowFormValue.ensPersonalInfo.email,
            phone: quoteEnsFlowFormValue.ensPersonalInfo.phone,
            birthday: quoteEnsFlowFormValue.ensPersonalInfo.birthday,
            maritalStatus: quoteEnsFlowFormValue.ensPersonalInfo.maritalStatus,
            currentInsurance: quoteEnsFlowFormValue.rc2?.currentInsurance || null,
            licenseStatus: quoteEnsFlowFormValue.rc2?.licenseStatus || null,
            yearsLicensed: quoteEnsFlowFormValue.rc2?.yearsLicensed || null,
            ssn: null,
            licenseNumber: null,
            licenseState: null,
            accidentsViolations: null,
            decPageProviderDriverId: null,
            gender: null,
            occupation: null,
            relationshipToInsured: null,
            hasSR22: null,
            hasFR44: null,
        };
    }

    canRemoveCoverage(rigSetIndex: number): boolean {
        if (!this._quoteEnsFlowFormValuePure$.value) {
            return false;
        }
        let coverages = 0;
        if (
            this._quoteEnsFlowFormValuePure$.value.rigSets[rigSetIndex].coverageSelect.modsCoverage
        ) {
            coverages++;
        }
        if (
            this._quoteEnsFlowFormValuePure$.value.rigSets[rigSetIndex].coverageSelect
                .camperCoverage
        ) {
            coverages++;
        }
        if (
            this._quoteEnsFlowFormValuePure$.value.rigSets[rigSetIndex].coverageSelect
                .trailerCoverage
        ) {
            coverages++;
        }
        if (coverages === 1) {
            this.confirmationService.confirm$({
                title: 'UNABLE TO REMOVE LAST COVERAGE',
                text: `You must have at least one covereage on your Rig`,
                confirmationLabel: 'OKAY',
                noCancel: true,
            });

            return false;
        }
        return true;
    }
}
