import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import {
    GearCoverageType,
    gearCoverageTypeText,
    OverlandGearAdjustmentsFormValue,
    QuoteEnsFlowFormValue,
} from '@backend-types/quote-flow-ens';
import { gearDeductibleText } from '@backend-types/roc-lib';
import { ModelFormGroup } from '@common/models';
import { valueLimits } from '@data/value-limits.data';
import {
    QuoteEnsFormService,
    QuoteEnsRetrievalService,
    RateIssueKind,
    RateIssueService,
    RoundingService,
    UXPremiumBreakdown,
} from '@modules/quote/services';
import compare from 'just-compare';
import { debounceTime, Subscription } from 'rxjs';

@Component({
    selector: 'sbf-coverage-adjustments-form-gear',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './coverage-adjustments-form-gear.component.html',
    styleUrls: ['coverage-adjustments-form-gear.component.scss'],
})
export class CoverageAdjustmentsFormGearComponent implements OnInit, OnDestroy {
    @Input() editValues = false;
    @Input() endorsement?: UUID;

    quoteEnsFlowForm!: ModelFormGroup<QuoteEnsFlowFormValue>;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;
    activeEnsQuote!: EnsResponseWithEnsOnly | null;

    premiumBreakdown!: UXPremiumBreakdown | null;

    valueLimits = valueLimits;
    eGearCoverageType = GearCoverageType;
    gearDeductibleText = gearDeductibleText;
    eGearCoverageTypeText = gearCoverageTypeText;
    gearValueRounded = false;

    subscription: Subscription = new Subscription();

    overlandGearAdjustmentsForm: ModelFormGroup<OverlandGearAdjustmentsFormValue> = this.fb.group(
        {
            gearValue: new FormControl<number | null>(null, [
                Validators.required,
                Validators.min(valueLimits.gear.min),
                Validators.max(valueLimits.gear.max),
            ]),
            coverageType: new FormControl<GearCoverageType | null>(null, Validators.required),
        }
        // {
        //     updateOn: 'blur',
        // }
    );

    gearDeductible = new FormControl(500);

    constructor(
        private fb: FormBuilder,
        private quoteEnsFormService: QuoteEnsFormService,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private router: Router,
        private roundingService: RoundingService,
        private rateIssueService: RateIssueService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowForm$.subscribe((quoteEnsFlowForm) => {
                this.quoteEnsFlowForm = quoteEnsFlowForm;
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValuePure$.subscribe(
                (quoteEnsFlowFormValuePure) => {
                    this._processQuoteEnsFlowForm(quoteEnsFlowFormValuePure);
                    this.changeDetectorRef.detectChanges();
                }
            )
        );

        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuote$.subscribe((activeEnsQuote) => {
                this.activeEnsQuote = activeEnsQuote;
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteEnsRetrievalService.activeEnsQuotePremiumBreakdown$.subscribe(
                (premiumBreakdown) => {
                    this.premiumBreakdown = premiumBreakdown;
                    // this._initForm();
                    this.changeDetectorRef.detectChanges();
                }
            )
        );

        this.subscription.add(
            this.coverageTypeControl.valueChanges.subscribe((coverageTypeControlValue) => {
                this.quoteEnsFlowFormValue.gearDetails!.gearCoverageType = coverageTypeControlValue;
                this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
            })
        );

        this.subscription.add(
            this.gearValueControl.valueChanges.pipe(debounceTime(400)).subscribe((gearValue) => {
                this.gearValueRounded =
                    this.roundingService.roundInputValue({
                        inputValue: gearValue,
                        inputValueControl: this.gearValueControl,
                        minValue: valueLimits.gear.min,
                        roudedTo: valueLimits.gear.roundedTo,
                    }) || this.gearValueRounded;

                this.changeDetectorRef.detectChanges();
                if (this.overlandGearAdjustmentsForm.valid) {
                    this.rateIssueService.unsetRateIssue(RateIssueKind.gearValue);
                    this.quoteEnsFlowFormValue.gearDetails!.gearValue = gearValue;
                    this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
                } else {
                    this.rateIssueService.setRateIssue(RateIssueKind.gearValue);
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addGear() {
        this.quoteEnsFlowFormValue.gearCoverage = true;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
        if (!this.quoteEnsFlowFormValue.gearDetails?.gearValue) {
            this.router.navigate(['/quote/ens'], {
                queryParams: {
                    'quote-ens-form-step': 'gear-details',
                    'jump-back-endorsement': this.endorsement,
                },
            });
        }
    }

    removeGear() {
        this.quoteEnsFlowFormValue.gearCoverage = false;
        this.quoteEnsFormService.setQuoteEnsFlowForm(this.quoteEnsFlowFormValue);
    }

    private _processQuoteEnsFlowForm(quoteEnsFlowFormValuePure: QuoteEnsFlowFormValue | null) {
        if (quoteEnsFlowFormValuePure === null) {
            return;
        }
        if (compare(this.quoteEnsFlowFormValue, quoteEnsFlowFormValuePure)) {
            return;
        }
        this.quoteEnsFlowFormValue = quoteEnsFlowFormValuePure;

        if (this.quoteEnsFlowFormValue.gearCoverage) {
            // TODO: Set default value when creating quote and then set from here.
            this.gearValueControl.setValue(
                this.quoteEnsFlowFormValue.gearDetails?.gearValue || null,
                { emitEvent: false }
            );
            this.coverageTypeControl.setValue(
                this.quoteEnsFlowFormValue.gearDetails?.gearCoverageType || null,
                { emitEvent: false }
            );
        }
    }

    /* Accessor Methods */

    // Gear
    get coverageTypeControl() {
        return this.overlandGearAdjustmentsForm.get('coverageType') as FormControl;
    }
    get gearValueControl() {
        return this.overlandGearAdjustmentsForm.get('gearValue') as FormControl;
    }
}
