import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: 'd627dc9669ba46aab1882f0cd35f209aa2c60795',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.0.16',
    dateFormatted: '2024-09-14--15-17',
    dateISOString: '2024-09-14T15:17:55.651Z',
};
