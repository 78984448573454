import type { Prisma } from './_prisma';

export type PartnerForResults = Prisma.PartnerGetPayload<{
    include: {
        mainContact: true;
    };
}>;

export const partnerForResultsInclude = {
    mainContact: true,
};

export interface CreatePartnerPayload {
    partnerStatus: PartnerStatus;
    partnerType: PartnerType;
    partnerModel: PartnerModel;
    details: PartnerDetails;
    mainContactUserId: UUID;
    brand?: string;
    url?: string;
    cpl?: string;
    cpc?: string;
    cpm?: string;
    flatRate?: string;
    smPartnerNumber?: string;
}
export interface ReadMultiplePartnersPayload {
    partnerIDs: string[];
}

export interface DeleteMultiplePartnersPayload {
    partnerIDs: string[];
}

export interface UpdatePartnerPayload {
    partnerStatus?: PartnerStatus;
    partnerType?: PartnerType;
    partnerModel?: PartnerModel;
    details?: PartnerDetails;
    mainContactUserId: UUID;
    brand?: string;
    url?: string;
    cpl?: string;
    cpc?: string;
    cpm?: string;
    flatRate?: string;
    smPartnerNumber?: string;
}

export type AllPartnersErrorCodes = 'ERROR_FINDING_PARTNERS' | 'PARTNERS_NOT_FOUND';
export type CreatePartnersErrorCodes = 'PARTNER_NAME_IN_USE' | 'ERROR_CREATING_PARTNER';
export type DeleteMultiplePartnersErrorCodes =
    | 'PARTNERS_NOT_FOUND'
    | 'ERROR_FINDING_PARTNERS'
    | 'ERROR_DELETING_PARTNERS';
export type DeletePartnerErrorCodes =
    | 'PARTNER_NOT_FOUND'
    | 'ERROR_FINDING_PARTNER'
    | 'ERROR_DELETING_PARTNER';
export type FindPartnerErrorCodes = 'ERROR_FINDING_PARTNER' | 'PARTNER_NOT_FOUND';
export type FindPartnersErrorCodes = 'ERROR_FINDING_PARTNERS' | 'NO_PARTNERS_FOUND';
export type SavePartnerErrorCodes = 'ERROR_SAVING_PARTNER';
export type UpdatePartnerErrorCodes = 'ERROR_UPDATING_PARTNER' | 'PARTNER_NOT_FOUND';

export enum PartnerStatus {
    pending = 'pending',
    active = 'active',
    paused = 'paused',
    deactivated = 'deactivated',
}

export enum PartnerType {
    influencer = 'influencer',
    brand = 'brand',
    upfitter = 'upfitter',
    website = 'website',
}

export const partnerTypeText: EnumMap<PartnerType> = {
    influencer: 'Influencer',
    brand: 'Brand',
    upfitter: 'Upfitter',
    website: 'Website',
};

export enum PartnerModel {
    cpl = 'cpl',
    cpc = 'cpc',
    cpm = 'cpm',
    flatRate = 'flatRate',
}

export interface PartnerDetails {
    reachCustomersAnswer: string;
    linksAndSocials: string;
}

export interface PartnerForResultsTyped extends Omit<PartnerForResults, 'details'> {
    partnerStatus: PartnerStatus;
    partnerType: PartnerType;
    partnerModel: PartnerModel;
    details: PartnerDetails;
}

export interface PartnerAppFormValue {
    partnerType: PartnerType;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    brand: string | null;
    reachCustomersAnswer: string;
    linksAndSocials: string;
}

export interface NewAppResponse {
    foundExistingUser: boolean;
    userID?: UUID;
    passwordResetToken?: string;
}

export interface PartnersPayload {
    name: string;
}

export interface PartnersResponse {
    partners: PartnerForResultsTyped[];
}
