import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MetaService } from '@common/services';
import { DynamicRouteData } from '@modules/navigation/models';
import { Observable } from 'rxjs';

@Injectable()
export class DynamicRouteDataService implements Resolve<DynamicRouteData | undefined> {
    constructor(private metaService: MetaService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<DynamicRouteData> | Promise<DynamicRouteData> | DynamicRouteData | undefined {
        console.log(route.data);

        return this.metaService.process(route.data.dynamicRouteDataKey || state.url);
    }
}
